import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import Page from '../layout/Page';
import Header from '../components/common/Header';
import Form from '../components/ContactForm';
import Section from '../components/common/Section';
import ContactSection from '../components/common/ContactSection';
import { COLORS } from '../constants.styles';
import { ContactInfoWrapper, ContactInfoTable } from '../page-styles/contact.styled';

export default ({ data }) => {
  const contactTitle = get(data, 'prismicAddressAndContact.data.title.text');
  const contactAddress1 = get(data, 'prismicAddressAndContact.data.address_line_1.text');
  const contactAddress2 = get(data, 'prismicAddressAndContact.data.address_line_2.text');
  const contactPhone = get(data, 'prismicAddressAndContact.data.contact_phone.text');
  const contactEmail = get(data, 'prismicAddressAndContact.data.contact_email.text');

  return (
    <Page>
      <Section>
        <Header>Contact</Header>
        <div className="container">
          <Form />
        </div>
      </Section>
      <ContactSection theme={COLORS.LIGHT_GREY}>
        <Header>{contactTitle}</Header>
        <ContactInfoWrapper>
          <ContactInfoTable>
            <tr>
              <td style={{ border: 'none' }}>Adresse:</td>
              <td style={{ border: 'none' }}>{contactAddress1}</td>
            </tr>
            <tr>
              <td />
              <td>{contactAddress2}</td>
            </tr>
            <tr>
              <td>Téléphone:</td>
              <td>
                <a href={`tel:${contactPhone}`}>{contactPhone}</a>
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </td>
            </tr>
          </ContactInfoTable>
        </ContactInfoWrapper>
      </ContactSection>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicAddressAndContact {
      data {
        title {
          text
        }
        address_line_1 {
          text
        }
        address_line_2 {
          text
        }
        contact_phone {
          text
        }
        contact_email {
          text
        }
      }
    }
  }
`;
