import styled from 'styled-components';

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContactInfoTable = styled.table`
  border: none;
`;
