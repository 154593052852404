import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

const FormContainer = styled.div`
  max-width: 600px;
  margin: auto;
`;

const SuccessMessage = styled.div`
  text-align: center;
`;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class ContactForm extends React.Component {
  state = {
    successfullySubmitted: false,
  };

  render = () => {
    return (
      <FormContainer>
        <Formik
          initialValues={{
            nom: undefined,
            societe: undefined,
            email: undefined,
            suject: undefined,
            message: '',
          }}
          onSubmit={values =>
            fetch('/contact', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({
                'form-name': 'contact',
                ...values,
              }),
            }).then(response => response.ok && this.setState({ successfullySubmitted: true }))
          }
          validationSchema={Yup.object().shape({
            nom: Yup.string().required('Obligatoire'),
            societe: Yup.string().required('Obligatoire'),
            email: Yup.string()
              .email('Doit être une adresse mail')
              .required('Obligatoire'),
            suject: Yup.string(),
            message: Yup.string(),
          })}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-group">
                <label htmlFor="votreNom">Votre nom (obligatoire)</label>
                <input
                  type="text"
                  name="nom"
                  className="form-control"
                  id="votreNom"
                  value={values.nom}
                  onChange={handleChange}
                />
                {errors.nom && touched.nom && <p className="text-danger">{errors.nom}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="votreSociete">Votre société (obligatoire)</label>
                <input
                  type="text"
                  name="societe"
                  className="form-control"
                  id="votreSociete"
                  value={values.societe}
                  onChange={handleChange}
                />
                {errors.societe && touched.societe && <p className="text-danger">{errors.societe}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="votreEmail">Votre email (obligatoire)</label>
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  id="votreEmail"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && <p className="text-danger">{errors.email}</p>}
              </div>
              <div className="form-group">
                <label htmlFor="sujet">Sujet</label>
                <select name="sujet" className="form-control" id="sujet" value={values.sujet} onChange={handleChange}>
                  <option />
                  <option>Nous rejoindre</option>
                  <option>Demande d’étude</option>
                  <option>Renseignement sur nos abonnements</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="votreMessage">Votre message</label>
                <textarea
                  type="text"
                  name="message"
                  className="form-control"
                  id="votreMessage"
                  value={values.message}
                  onChange={handleChange}
                />
              </div>

              {this.state.successfullySubmitted ? (
                <SuccessMessage>
                  <h2>Votre message a été envoyé avec succès</h2>
                </SuccessMessage>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Envoyer
                </button>
              )}
            </form>
          )}
        </Formik>
      </FormContainer>
    );
  };
}

export default ContactForm;
